<template>
  <!-- FOR WRONG ROUTES TEMPLATE -->
  <v-app>
    <v-card elevation="0" class="d-flex justify-center" style="margin-top: 13%">
      <img src="../assets/logo1.png" alt="" />
    </v-card>
    <v-card elevation="0" class="d-flex justify-center">
      <span style="color: #777777">The requested URL was not found </span>
    </v-card>
    <v-card elevation="0" class="d-flex justify-center">
      <span style="color: #777777">That’s all we know. </span>
    </v-card>
    <v-card elevation="0" class="d-flex justify-center">
      <img src="../assets/error.png" alt="" style="width: 300px" />
    </v-card>
    <v-card elevation="0" class="d-flex justify-center">
      <v-btn small color="#F87279" style="color: white" to="/login">
        <v-icon small>mdi-arrow-left</v-icon> Back To Login</v-btn
      >
    </v-card>
  </v-app>
</template>
<script>
export default {
  async created() {},
};
</script>

<style lang="scss">
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}
</style>
